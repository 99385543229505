import React from "react";
import logo from "../../assets/images/cebikaaelogowhite.png";

function Footer() {
  return (
    <div className="bg-[#252525] flex flex-col w-[100%] justify-center items-center bg-primary-800 text-white py-20">
      <div className="flex flex-col items-center">
        <img src={logo} alt="logo" className="w-30 h-20 mb-5" />
      </div>
      <div className="mt-2">
        <span className="text-lg">Dubai, UAE</span>
      </div>
      <div className="flex flex-col text-center mt-2">
        <span> 2024 All Rights Reserved</span>
      </div>
    </div>
  );
}

export default Footer;
