import React, { useEffect } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import { NavLink } from "react-router-dom";
import featureImg from "../assets/images/feature-5.webp";

function DistributionService() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="py-[7rem]">
      <div className="flex flex-col justify-center items-center ">
        <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
          <div className="grid sm:grid-cols-2 sm:items-center gap-8">
            <div className="sm:order-2">
              <div className="relative pt-[50%] sm:pt-[100%] rounded-lg">
                <img
                  className="w-full h-full absolute top-0 start-0 object-cover rounded-lg"
                  src={featureImg}
                  alt="supply chain management"
                />
              </div>
            </div>

            <div className="flex flex-col sm:order-1">
              <h2 className="text-2xl font-bold md:text-3xl lg:text-4xl lg:leading-tight xl:text-5xl xl:leading-tight text-gray-800 ">
                <span className="text-primary">
                  Seamless Logistics, Timely Deliveries
                </span>
              </h2>
              <p className="mt-3 text-base text-gray-500">
                Efficient Supply Chain Management Solutions
              </p>
              <span className="mt-4 leading-relaxed">
                Cebika excels in the seamless management of storing,
                distributing, and delivering products, ensuring efficiency
                throughout the supply chain. Our completed projects, such as
                distribution network optimization in Saudi Arabia has been
                instrumental in guaranteeing timely deliveries and fostering
                customer satisfaction. In Saudi Arabia, we've established
                partnerships with major pharmacies like Nahdi and Al Dawa for
                the distribution of medical devices and makeup products.
                Moreover, our collaboration with hospitals and our robust
                presence in the online ecommerce sector further strengthens our
                ability to ensure the smooth flow of essential products.
              </span>
            </div>
          </div>

          <div className="mt-10 flex flex-col md:flex-row w-full gap-2 justify-between items-center">
            <NavLink to="/services/poultry" className="w-full">
              <div className="group transition-all ease-in-out duration-300 cursor-pointer flex flex-col justify-end gap-2 bg-slate-200 p-10 items-start border border-transparent text-base font-medium rounded-md hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-dark">
                <div className="flex items-center text-slate-400 gap-1 group-hover:gap-2">
                  <FaChevronLeft />
                  <small className="">Previous</small>
                </div>
                <span>Lifestock Farming</span>
              </div>
            </NavLink>
            <NavLink to="/services/consultation" className="w-full">
              <div className="group transition-all ease-in-out duration-300 cursor-pointer flex flex-col justify-end gap-2 bg-slate-200 p-10 items-end border border-transparent text-base font-medium rounded-md hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-dark">
                <div className="flex items-center text-slate-400 gap-1 group-hover:gap-2">
                  <small className="">Next</small>
                  <FaChevronRight />
                </div>
                <span>Consultations And Advisory</span>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DistributionService;
