import React from "react";
import { NavLink } from "react-router-dom";
import heroImage from "../assets/images/hero.webp";

function Hero() {
  return (
    <div class="overflow-hidden  mt-[7rem] py-[2rem] md:pt-[5rem] md:pb-[1rem] max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8">
      <div class="grid lg:grid-cols-7 lg:gap-x-8 xl:gap-x-12 lg:items-center">
        <div class="lg:col-span-3">
          <h1 class="block text-3xl font-bold text-gray-800 sm:text-4xl md:text-5xl lg:text-6xl ">
            Innovating Industries, <br />
            <span className="text-primary">Elevating Standards.</span>
          </h1>
          <h2 class="mt-4 text-lg text-gray-400 ">
            Unveiling Transformative Ventures Across Africa{" "}
          </h2>
          <p class="mt-3 text-lg text-gray-800 text-justify">
            Discover the narratives of transformation and innovation as{" "}
            <span className="text-primary">Cebika</span> leaves an indelible
            mark across Africa and the Middle East. Explore our ventures in
            agriculture, technology, and trade, shaping industries and
            empowering communities. Join us in our journey to drive progress and
            create lasting impact.
          </p>

          <div class="mt-5 lg:mt-8 flex flex-col items-center gap-2 sm:flex-row sm:gap-3">
            <NavLink to="services/registering-distribution">
              <div class="w-full sm:w-auto py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary text-white hover:bg-blue-500 disabled:opacity-50 disabled:pointer-events-none">
                Explore Our Initiatives
              </div>
            </NavLink>
          </div>
        </div>

        <div class="lg:col-span-4 mt-10 lg:mt-0">
          <img class="w-full rounded-xl" src={heroImage} alt=" Description" />
        </div>
      </div>

      <div className="mt-10 flex w-full justify-center md:justify-end gap-10">
        <div className="flex end flex-col items-center gap-1">
          <span className="text-5xl font-bold">7</span>
          <div class="mt-2 sm:mt-6">
            <h3 class="text-lg font-semibold text-gray-800 ">Countries</h3>
          </div>
        </div>
        <div className="flex flex-col items-center gap-1">
          <span className="text-5xl font-bold">8</span>
          <div class="mt-2 sm:mt-6">
            <h3 class="text-lg font-semibold text-gray-800 ">
              Success Stories
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
