import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";

function Layout() {
  return (
    <div className="min-h-[100vh] flex flex-col">
      <Header />
      <div className="grow flex-1">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default Layout;
