import React, { useEffect } from "react";
import { TbBulb } from "react-icons/tb";
import { RiMindMap } from "react-icons/ri";
import { LiaHandsHelpingSolid } from "react-icons/lia";
import { FaRegStar } from "react-icons/fa6";
import { BsShieldCheck } from "react-icons/bs";
import aboutUsHero from "../assets/images/aboutus.webp";
import aboutUsOne from "../assets/images/aboutus1.webp";
import aboutUsTwo from "../assets/images/aboutus2.webp";

function AboutUsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="pt-[7vh]">
      <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <div className="aspect-w-16 aspect-h-7">
          <img
            className="w-full h-[20rem] object-cover rounded-xl"
            src={aboutUsHero}
            alt="Business Meeting"
          />
        </div>

        <div className="mt-5 lg:mt-16 grid lg:grid-cols-3 gap-8 lg:gap-12">
          <div className="lg:col-span-1">
            <h2 className="font-bold text-2xl md:text-3xl text-gray-800 ">
              Innovating Industries, Elevating Standards.
            </h2>
            <p className="mt-2 md:mt-4 text-gray-500 text-justify">
              Pioneering industrial projects, distributing critical medical
              resources, and enhancing veterinary services across Africa and the
              Middle East. Our commitment to excellence and innovation shapes
              industries and fosters sustainable growth
            </p>
          </div>

          <div className="lg:col-span-2">
            <div className="grid sm:grid-cols-2 gap-8 md:gap-12">
              <div className="flex gap-x-5">
                <TbBulb className="flex-shrink-0 mt-1 w-6 h-6 text-primary " />
                <div className="grow">
                  <h3 className="text-lg font-semibold text-gray-800 ">
                    Creative minds
                  </h3>
                  <p className="mt-1 text-gray-600 text-justify">
                    We meticulously select our teams, understanding that our
                    people are the key to exceptional work.
                  </p>
                </div>
              </div>

              <div className="flex gap-x-5">
                <RiMindMap className="flex-shrink-0 mt-1 w-6 h-6 text-primary" />
                <div className="grow">
                  <h3 className="text-lg font-semibold text-gray-800 ">
                    Forward-Thinking Solutions
                  </h3>
                  <p className="mt-1 text-gray-600 text-justify">
                    Our presence in diverse markets underscores our commitment
                    to pioneering solutions that empower growth, foster
                    innovation, and contribute to the sustained development of
                    the regions we serve.
                  </p>
                </div>
              </div>

              <div className="flex gap-x-5">
                <LiaHandsHelpingSolid className="flex-shrink-0 mt-1 w-6 h-6 text-primary " />
                <div className="grow">
                  <h3 className="text-lg font-semibold text-gray-800 ">
                    Collaborative Excellence
                  </h3>
                  <p className="mt-1 text-gray-600 text-justify">
                    Collaboration fuels our success. We foster an environment
                    where collaboration thrives, uniting diverse talents and
                    expertise to achieve extraordinary results. Together, we
                    navigate challenges, innovate, and deliver unparalleled
                    outcomes for our clients and the communities we serve.
                  </p>
                </div>
              </div>

              <div className="flex gap-x-5">
                <FaRegStar className="flex-shrink-0 mt-1 w-6 h-6 text-primary " />
                <div className="grow">
                  <h3 className="text-lg font-semibold text-gray-800 ">
                    Innovative Leadership
                  </h3>
                  <p className="mt-1 text-gray-600 text-justify">
                    At Cebika, visionary leadership drives our pursuit of
                    groundbreaking solutions. Our leadership team champions
                    innovation, guiding our endeavors to redefine industry
                    standards and spearhead transformative initiatives.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr className="my-10 border-gray-200 " />

        <div className="mt-5 vision__section grid grid-cols-3 gap-5 items-center justify-between">
          <div className="col-span-2">
            <h3 className="font-bold text-md md:text-2xl text-gray-500 bg-slate-200 w-fit px-2 rounded-md">
              Our Vision
            </h3>
            <h2 className="font-bold text-2xl md:text-6xl text-gray-800 mt-1">
              Sustainability, Innovation, and Integrity{" "}
              <span className="text-primary">.</span>
            </h2>
            <p className="mt-2 md:mt-4 text-gray-500 text-justify">
              <span className="text-primary font-bold">Cebika</span> is
              celebrating a vision as the trailblazer of transformation in the
              Middle East and Africa, We aim to revolutionize industries,
              driving sustainable growth and pioneering innovations
            </p>
          </div>
          <div className="col-span-1">
            <img
              src={aboutUsOne}
              alt="Vision"
              className="w-full h-[20rem] object-cover rounded-xl mt-5"
            />
          </div>
        </div>

        <hr className="my-10 border-gray-200 " />

        <div className="mt-5 vision__section grid grid-cols-3 gap-5 items-center justify-between">
          {" "}
          <div className="col-span-1">
            <img
              src={aboutUsTwo}
              alt="mission"
              className="w-full h-[20rem] object-cover rounded-xl mt-5"
            />
          </div>
          <div className="col-span-2">
            <h3 className="font-bold text-md md:text-2xl text-gray-500 bg-slate-200 w-fit px-2 rounded-md">
              Our Mission
            </h3>
            <h2 className="font-bold text-2xl md:text-6xl text-gray-800 mt-1">
              Redefining Industry Landscapes
              <span className="text-primary">.</span>
            </h2>
            <p className="mt-2 md:mt-4 text-gray-500 text-justify">
              Empowered by a steadfast mission, we at{" "}
              <span className="text-primary font-bold">Cebika</span> are
              committed to redefining the landscape of the Middle East and
              Africa. Our mission is to lead by example, catalyzing the
              transformation of industries through innovation, fostering
              sustainable growth, and pioneering groundbreaking solutions.
            </p>
          </div>
        </div>

        <hr className="my-10 border-gray-200 " />

        <div className="mt-5 values__section">
          <div className="py-10 lg:py-6">
            <div className="">
              <div className="grid">
                <div>
                  <h2 className="font-bold text-2xl md:text-3xl text-gray-800">
                    Our Values
                  </h2>
                </div>

                <div className="mt-6 space-y-6 lg:space-y-6">
                  <div className="flex">
                    <BsShieldCheck className="flex-shrink-0 mt-2 h-6 w-6 text-primary" />
                    <div className="ms-5 sm:ms-8">
                      <h3 className="text-base sm:text-lg font-semibold text-gray-800">
                        Excellence
                      </h3>
                      <p className="mt-1 text-gray-600 ">
                        We are committed to delivering exceptional quality in
                        every aspect of our work.
                      </p>
                    </div>
                  </div>

                  <div className="flex">
                    <BsShieldCheck className="flex-shrink-0 mt-2 h-6 w-6 text-primary " />
                    <div className="ms-5 sm:ms-8">
                      <h3 className="text-base sm:text-lg font-semibold text-gray-800 ">
                        Innovation
                      </h3>
                      <p className="mt-1 text-gray-600 ">
                        Innovation fuels our progress, driving us to
                        continuously explore new ideas and solutions.
                      </p>
                    </div>
                  </div>

                  <div className="flex">
                    <BsShieldCheck className="flex-shrink-0 mt-2 h-6 w-6 text-primary" />
                    <div className="ms-5 sm:ms-8">
                      <h3 className="text-base sm:text-lg font-semibold text-gray-800 ">
                        Integrity
                      </h3>
                      <p className="mt-1 text-gray-600 ">
                        We conduct our business with honesty, transparency, and
                        a strong ethical foundation.
                      </p>
                    </div>
                  </div>

                  <div className="flex">
                    <BsShieldCheck className="flex-shrink-0 mt-2 h-6 w-6 text-primary " />
                    <div className="ms-5 sm:ms-8">
                      <h3 className="text-base sm:text-lg font-semibold text-gray-800 ">
                        Collaboration
                      </h3>
                      <p className="mt-1 text-gray-600 ">
                        We believe in the power of collaboration, uniting
                        diverse talents for collective success.
                      </p>
                    </div>
                  </div>

                  <div className="flex">
                    <BsShieldCheck className="flex-shrink-0 mt-2 h-6 w-6 text-primary " />
                    <div className="ms-5 sm:ms-8">
                      <h3 className="text-base sm:text-lg font-semibold text-gray-800 ">
                        Responsibility
                      </h3>
                      <p className="mt-1 text-gray-600 ">
                        We are dedicated to being responsible corporate
                        citizens, contributing positively to the communities we
                        serve.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUsPage;
