import React from "react";
import map from "../assets/images/map.png";
import { NavLink } from "react-router-dom";

function MapPresence() {
  return (
    <div className="flex w-full justify-center items-center mt-8">
      <div className="w-full sm:grid-cols-1 grid md:grid-cols-2 items-center">
        <img src={map} alt="map" className="col-span-1" />
        <div className="col-span-1 bg-primary text-white h-full flex justify-center items-center text-center flex-col gap-2 p-10">
          <h1 className="text-3xl font-bold ">Regional Reach, Local Impact</h1>
          <p className="text-xl font-semibold ">
            Navigating Diverse Territories with Expertise
          </p>
          <span className="text-justify">
            Explore our footprint across multiple regions, from the United Arab
            Emirates to Congo DRC, Mauritania, Mozambique, and Saudi Arabia. Our
            diverse presence reflects our commitment to making a tangible
            difference in each community we serve.
          </span>
          <NavLink className="mt-5 cursor-pointer" to="markets">
            <span className="bg-white text-primary font-semibold py-2 px-6 rounded-md w-fit transition-all ease-in-out duration-500 hover:bg-primary hover:border-2 hover:py-2 hover:text-white">
              Explore our footprint
            </span>
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default MapPresence;
