export const mantineTheme = {
  colors: {
    primary: [
      "#00a9ff",
      "#00a9ff",
      "#00a9ff",
      "#00a9ff",
      "#00a9ff",
      "#00a9ff",
      "#00a9ff",
      "#00a9ff",
    ],
  },
};
