import React, { useEffect } from "react";

function MarketResearchService() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="py-[7rem]">
      <div className="flex flex-col justify-center items-center ">
        <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
          <div className="grid sm:grid-cols-2 sm:items-center gap-8">
            <div className="sm:order-2">
              <div className="relative pt-[50%] sm:pt-[100%] rounded-lg">
                <img
                  className="w-full h-full absolute top-0 start-0 object-cover rounded-lg"
                  src="https://images.unsplash.com/photo-1608303588026-884930af2559?q=80&w=2606&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  alt="market reserach"
                />
              </div>
            </div>

            <div className="flex flex-col sm:order-1">
              <h2 className="text-2xl font-bold md:text-3xl lg:text-4xl lg:leading-tight xl:text-5xl xl:leading-tight text-gray-800">
                <span className="text-primary ">
                  Insight-Driven Strategies for Growth
                </span>
              </h2>
              <p className="mt-3 text-base text-gray-500 ">
                Uncovering Opportunities in African and Middle Eastern Markets
              </p>
              <span className="mt-4 leading-relaxed">
                Cebika is your strategic partner for growth, offering
                comprehensive market research services tailored to the unique
                landscapes of Africa and the Middle East. Completed market
                research studies in Congo DRC and Mozambique have provided
                invaluable insights. Currently, we are expanding our research
                services in Saudi Arabia and Mauritania, aiming to uncover new
                opportunities and facilitate informed decision-making.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MarketResearchService;
