import React from "react";

function Careers() {
  return (
    <div className="pt-[8rem] my-10 flex flex-col gap-5 text-center justify-center items-center">
      <h1 className="text-5xl font-bold">Careers</h1>
      <p className="text-xl text-gray-500 max-w-[30%]">
        We are always looking for talented people to join our team. If you are
        interested in working with us, please send your resume to{" "}
        <a className="text-blue-500 underline" href="mailto:careers@cebika.ae">
          our email
        </a>
      </p>
      <img
        className="w-[40%] h-[50%] object-cover rounded-lg"
        src="https://images.unsplash.com/photo-1557200134-90327ee9fafa?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        alt=""
      />
    </div>
  );
}

export default Careers;
