import React, { useEffect } from "react";
import Hero from "../components/Hero";
import Services from "../components/Services";
import MapPresence from "../components/MapPresence";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Hero />
      <Services />
      <MapPresence />
    </>
  );
}

export default Home;
