import React, { useEffect, useState } from "react";
import heroImage from "../assets/images/marketsHero.webp";
import moodmatcher from "../assets/images/moodmatcher.jpeg";
import feellife from "../assets/images/feellife.jpeg";
import italfarmica from "../assets/images/italfarmacia.svg";
import pangao from "../assets/images/pangao.webp";
import pcl from "../assets/images/pcl.jpeg";
import ihealth from "../assets/images/ihealth.png";
import ofi from "../assets/images/ofi.jpeg";
import catalysis from "../assets/images/catalysis.png";
import burnfree from "../assets/images/burnfree.jpeg";
import ambix from "../assets/images/ambix.jpeg";
import lifegate from "../assets/images/lifegate.webp";
import foraisha from "../assets/images/foraisha.svg";
import deva from "../assets/images/deva.png";
import fresh from "../assets/images/fresh.svg";
import viva from "../assets/images/viva.jpeg";
import soocom from "../assets/images/soocom.png";
import athar from "../assets/images/athar.jpg";

function Markets() {
  const [selectedCountry, setSelectedCountry] = useState("uae");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="pt-[7rem]">
      <div className="my-7 max-w-[85rem] flex flex-col-reverse mx-5 md:flex-row md:mx-auto gap-5 justify-center items-center">
        <div className="max-w-lg text-center mx-auto">
          <h1 className="block text-3xl font-bold text-gray-800 sm:text-4xl md:text-5xl ">
            Territory Tales: <br />
            <span className="text-primary">Transforming Regions</span>
          </h1>
          <p className="mt-3 text-lg text-gray-800 text-justify">
            At Cebika, our reach extends across strategic markets within Africa
            and the Middle East. Our commitment to these regions drives our
            mission to catalyze growth, foster innovation, and address unique
            challenges. Explore our engagements in the United Arab Emirates,
            Saudi Arabia, Congo DRC, Mauritania, Mozambique, and Egypt,
            witnessing how we navigate diverse landscapes to create meaningful
            impact in each territory.
          </p>
        </div>
        <div className="max-w-2xl text-center mx-auto">
          <img
            className="w-full rounded-xxl shadow-md"
            src={heroImage}
            alt="dubai skyline"
          />
        </div>
      </div>

      <div className="my-14">
        <div className="mx-auto">
          <div className="tabs flex flex-wrap w-full justify-center gap-2">
            <button
              className={`tab tab-lifted bg-slate-300 px-5 py-3 rounded-md cursor-pointer ${
                selectedCountry === "uae" && "bg-slate-400"
              }`}
              onClick={() => setSelectedCountry("uae")}
            >
              🇦🇪 United Arab Emirates
            </button>{" "}
            <button
              className={`tab tab-lifted bg-slate-300 px-5 py-3 rounded-md cursor-pointer ${
                selectedCountry === "saudi" && "bg-slate-400"
              }`}
              onClick={() => setSelectedCountry("saudi")}
            >
              🇸🇦 Saudi Arabia
            </button>{" "}
            <button
              className={`tab tab-lifted bg-slate-300 px-5 py-3 rounded-md cursor-pointer ${
                selectedCountry === "egypt" && "bg-slate-400"
              }`}
              onClick={() => setSelectedCountry("egypt")}
            >
              🇪🇬 Egypt
            </button>
            <button
              className={`tab tab-lifted bg-slate-300 px-5 py-3 rounded-md cursor-pointer ${
                selectedCountry === "congo" && "bg-slate-400"
              }`}
              onClick={() => setSelectedCountry("congo")}
            >
              🇨🇩 Congo DRC
            </button>
            <button
              className={`tab tab-lifted bg-slate-300 px-5 py-3 rounded-md cursor-pointer ${
                selectedCountry === "mauritania" && "bg-slate-400"
              }`}
              onClick={() => setSelectedCountry("mauritania")}
            >
              🇲🇷 Mauritania
            </button>
            <button
              className={`tab tab-lifted bg-slate-300 px-5 py-3 rounded-md cursor-pointer ${
                selectedCountry === "mozambique" && "bg-slate-400"
              }`}
              onClick={() => setSelectedCountry("mozambique")}
            >
              🇲🇿 Mozambique
            </button>
            <button
              className={`tab tab-lifted bg-slate-300 px-5 py-3 rounded-md cursor-pointer ${
                selectedCountry === "tanzania" && "bg-slate-400"
              }`}
              onClick={() => setSelectedCountry("tanzania")}
            >
              🇹🇿 Tanzania
            </button>
          </div>

          <hr className="border-t-2 mt-10" />

          <div className="mt-2">
            {selectedCountry === "uae" ? (
              <div className="max-w-[85rem] mx-auto px-4 mt-6 sm:px-6 lg:px-8">
                <div className="grid lg:grid-cols-9 lg:gap-x-8 xl:gap-x-12 lg:items-center">
                  <div className="lg:col-span-6">
                    <h1 className="block text-3xl font-bold text-gray-800 sm:text-4xl md:text-5xl lg:text-6xl ">
                      Cebika UAE
                    </h1>
                    <p className="mt-3 text-lg text-gray-800 text-justify">
                      At the core of our operations in the UAE, We play a
                      crucial role in facilitating the registration and
                      distribution of advanced medical devices and makeup
                      products throughout the emirates. Navigating intricate
                      regulatory pathways, We ensure the seamless introduction
                      of these innovative solutions, reaching major pharmacies
                      and key healthcare institutions.
                    </p>
                  </div>
                </div>

                <div className="mt-2 gap-5 grid grid-cols-4">
                  <div class="col-span-1 p-4 md:p-7 flex justify-center items-center bg-gray-100 rounded-lg ">
                    <img
                      className="w-[300px] rounded-xxl shadow-sm"
                      src={moodmatcher}
                      alt="company logo"
                    />
                  </div>
                  <div class="col-span-1 p-4 md:p-7 flex justify-center items-center bg-gray-100 rounded-lg ">
                    <img
                      className="w-[300px] rounded-xxl shadow-sm"
                      src={feellife}
                      alt="company logo"
                    />
                  </div>
                  <div class="col-span-1 p-4 md:p-7 flex justify-center items-center bg-gray-100 rounded-lg ">
                    <img
                      className="w-[300px] rounded-xxl"
                      src={italfarmica}
                      alt="company logo"
                    />
                  </div>
                  <div class="col-span-1 p-4 md:p-7 flex justify-center items-center bg-gray-100 rounded-lg ">
                    <img
                      className="w-[300px] rounded-xxl shadow-sm"
                      src={pangao}
                      alt="company logo"
                    />
                  </div>
                  <div class="col-span-1 p-4 md:p-7 flex justify-center items-center bg-gray-100 rounded-lg ">
                    <img
                      className="w-[300px] rounded-xxl shadow-sm"
                      src={pcl}
                      alt="company logo"
                    />
                  </div>
                  <div class="col-span-1 p-4 md:p-7 flex justify-center items-center bg-gray-100 rounded-lg ">
                    <img
                      className="w-[300px] rounded-xxl shadow-sm"
                      src={ihealth}
                      alt="company logo"
                    />
                  </div>
                </div>
              </div>
            ) : null}

            {selectedCountry === "saudi" ? (
              <>
                <div className="max-w-[85rem] mx-auto px-4 mt-6 sm:px-6 lg:px-8">
                  <div className="grid lg:grid-cols-9 lg:gap-x-8 xl:gap-x-12 lg:items-center">
                    <div className="lg:col-span-6">
                      <h1 className="block text-3xl font-bold text-gray-800 sm:text-4xl md:text-5xl lg:text-6xl ">
                        Life Gate Saudi Arabia
                      </h1>
                      <p className="mt-3 text-lg text-gray-800 text-justify">
                        As a cornerstone of our operations in Saudi Arabia, Life
                        Gate plays a pivotal role in licensing, registering, and
                        distributing cutting-edge medical devices and makeup
                        products across the region. Seamlessly navigating
                        regulatory frameworks, Life Gate ensures these
                        innovative solutions reach major pharmacies like Nahdi
                        and Al Shifa, as well as pivotal healthcare
                        institutions. Discover how Life Gate's endeavors not
                        only meet market demands but also elevate standards in
                        healthcare and beauty sectors throughout Saudi Arabia.
                      </p>
                      <a
                        href="https://lifegate.us"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="mt-2 py-3 rounded-md text-primary">
                          {"> "}Learn More
                        </div>
                      </a>
                    </div>

                    <div className="lg:col-span-3 mt-10 lg:mt-0">
                      <img
                        className="w-full rounded-xxl shadow-sm"
                        src={lifegate}
                        alt="Description"
                      />
                    </div>
                  </div>

                  <div className="mt-2 gap-5 grid grid-cols-4">
                    <div class="col-span-1 p-4 md:p-7 flex justify-center items-center bg-gray-100 rounded-lg ">
                      <img
                        className="w-[300px] rounded-xxl shadow-sm"
                        src={moodmatcher}
                        alt="company logo"
                      />
                    </div>
                    <div class="col-span-1 p-4 md:p-7 flex justify-center items-center bg-gray-100 rounded-lg ">
                      <img
                        className="w-[300px] rounded-xxl shadow-sm"
                        src={feellife}
                        alt="company logo"
                      />
                    </div>
                    <div class="col-span-1 p-4 md:p-7 flex justify-center items-center bg-gray-100 rounded-lg ">
                      <img
                        className="w-[300px] rounded-xxl"
                        src={italfarmica}
                        alt="company logo"
                      />
                    </div>
                    <div class="col-span-1 p-4 md:p-7 flex justify-center items-center bg-gray-100 rounded-lg ">
                      <img
                        className="w-[300px] rounded-xxl shadow-sm"
                        src={ofi}
                        alt="company logo"
                      />
                    </div>
                    <div class="col-span-1 p-4 md:p-7 flex justify-center items-center bg-gray-100 rounded-lg ">
                      <img
                        className="w-[300px] rounded-xxl"
                        src={catalysis}
                        alt="company logo"
                      />
                    </div>
                    <div class="col-span-1 p-4 md:p-7 flex justify-center items-center bg-gray-100 rounded-lg ">
                      <img
                        className="w-[300px] rounded-xxl shadow-sm"
                        src={burnfree}
                        alt="company logo"
                      />
                    </div>
                    <div class="col-span-1 p-4 md:p-7 flex justify-center items-center bg-gray-100 rounded-lg ">
                      <img
                        className="w-[300px] rounded-xxl shadow-sm"
                        src={ambix}
                        alt="company logo"
                      />
                    </div>
                    <div class="col-span-1 p-4 md:p-7 flex justify-center items-center bg-gray-100 rounded-lg ">
                      <img
                        className="w-[300px] rounded-xxl shadow-sm"
                        src={pcl}
                        alt="company logo"
                      />
                    </div>
                    <div class="col-span-1 p-4 md:p-7 flex justify-center items-center bg-gray-100 rounded-lg ">
                      <img
                        className="w-[300px] rounded-xxl shadow-sm"
                        src={pangao}
                        alt="company logo"
                      />
                    </div>
                  </div>
                </div>

                <hr className="border-t-2 mt-10" />

                <div className="max-w-[85rem] mx-auto px-4 mt-6 sm:px-6 lg:px-8">
                  <div className="grid lg:grid-cols-9 lg:gap-x-8 xl:gap-x-12 lg:items-center justify-between">
                    <div className="lg:col-span-6">
                      <h1 className="block text-3xl font-bold text-gray-800 sm:text-4xl md:text-5xl lg:text-6xl ">
                        Athar
                      </h1>
                      <p className="mt-3 text-lg text-gray-800 text-justify">
                        In Saudi Arabia, Athar serves as our technological arm,
                        specializing in AI, data analytics, and software
                        engineering services. Athar's expertise contributes to
                        advancing technological capabilities and driving
                        innovation in Saudi Arabia's evolving landscape. These
                        endeavors underscore our commitment to excellence,
                        innovation, and growth across diverse sectors in Saudi
                        Arabia.
                      </p>
                    </div>

                    <div className="lg:col-span-3 mt-10 lg:mt-0">
                      <img
                        className="w-[100%] md:w-full rounded-xxl shadow-sm"
                        src={athar}
                        alt="Description"
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : null}

            {selectedCountry === "egypt" ? (
              <>
                <div className="max-w-[85rem] mx-auto px-4 mt-6 sm:px-6 lg:px-8">
                  <div className="grid lg:grid-cols-9 lg:gap-x-8 xl:gap-x-12 lg:items-center">
                    <div className="lg:col-span-6">
                      <h1 className="block text-3xl font-bold text-gray-800 sm:text-4xl md:text-5xl lg:text-6xl ">
                        Life Gate Egypt
                      </h1>
                      <p className="mt-3 text-lg text-gray-800 text-justify">
                        As the linchpin of our operations in Egypt, LifeGate
                        spearheads the licensing, registration, and distribution
                        of cutting-edge Feellife medical devices, Mood Matcher
                        makeup products, and premium baby food offerings such as
                        For Aisha and Deva. Navigating intricate regulatory
                        landscapes seamlessly, LifeGate ensures these innovative
                        solutions reach major market players and pivotal
                        healthcare institutions.
                      </p>
                      <p className="mt-3 text-lg text-gray-800 text-justify">
                        LifeGate Egypt isn't just meeting market demands; it's
                        driving a paradigm shift in healthcare, beauty, and
                        infant nutrition sectors across Egypt. Through strategic
                        alliances and a relentless commitment to quality,
                        LifeGate elevates standards and accessibility, setting
                        new benchmarks for excellence in these vital sectors.
                      </p>

                      <a
                        href="https://lifegate.us"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="mt-2 py-3 rounded-md text-primary">
                          {"> "}Learn More
                        </div>
                      </a>
                    </div>

                    <div className="lg:col-span-3 mt-10 lg:mt-0">
                      <img
                        className="w-full rounded-xxl shadow-sm"
                        src={lifegate}
                        alt="Description"
                      />
                    </div>
                  </div>

                  <div className="mt-2 flex gap-8">
                    <div class="p-4 md:p-7 flex justify-center items-center bg-gray-100 rounded-lg ">
                      <img
                        className="w-[300px] rounded-xxl shadow-sm"
                        src={moodmatcher}
                        alt="company logo"
                      />
                    </div>
                    <div class="p-4 md:p-7 flex justify-center items-center bg-gray-100 rounded-lg ">
                      <img
                        className="w-[300px] rounded-xxl shadow-sm"
                        src={feellife}
                        alt="company logo"
                      />
                    </div>
                    <div class="p-4 md:p-7 flex justify-center items-center bg-gray-100 rounded-lg ">
                      <img
                        className="w-[300px] rounded-xxl "
                        src={foraisha}
                        alt="company logo"
                      />
                    </div>
                    <div class="p-4 md:p-7 flex justify-center items-center bg-gray-100 rounded-lg ">
                      <img
                        className="w-[300px] rounded-xxl "
                        src={deva}
                        alt="company logo"
                      />
                    </div>
                    <div class="p-4 md:p-7 flex justify-center items-center bg-gray-100 rounded-lg ">
                      <img
                        className="w-[300px] rounded-xxl "
                        src={ihealth}
                        alt="company logo"
                      />
                    </div>
                  </div>
                </div>

                <hr className="border-t-2 mt-10" />

                <div className="max-w-[85rem] mx-auto px-4 mt-6 sm:px-6 lg:px-8">
                  <div className="grid lg:grid-cols-9 lg:gap-x-8 xl:gap-x-12 lg:items-center justify-between">
                    <div className="lg:col-span-6">
                      <h1 className="block text-3xl font-bold text-gray-800 sm:text-4xl md:text-5xl lg:text-6xl ">
                        ARAB DEVELOPMENT & REAL ESTATE INVESTMENT S.A.E
                      </h1>
                      <p className="mt-3 text-lg text-gray-800 text-justify">
                        As a prominent entity listed on the Nilex stocks in
                        Egypt, Arab Development & Real Estate Investment S.A.E
                        spearheads real estate investment and contracting
                        endeavors across Egypt. With a steadfast commitment to
                        excellence, our company specializes in real estate
                        development, infrastructure projects, and contracting
                        work, contributing to the architectural landscape and
                        infrastructural growth in Egypt. Our presence on the
                        Nilex stocks underscores our dedication to delivering
                        exceptional real estate solutions and fostering economic
                        development within the region.
                      </p>
                    </div>

                    <div className="lg:col-span-2 mt-10 lg:mt-3">
                      <img
                        className="w-[50%] md:w-full rounded-xxl shadow-sm"
                        src="https://arab-egypt.com/wp-content/uploads/2021/11/logo-arab.png"
                        alt="Description"
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : null}

            {selectedCountry === "congo" ? (
              <div className="max-w-[85rem] mx-auto px-4 mt-6 sm:px-6 lg:px-8">
                <div className="grid lg:grid-cols-9 lg:gap-x-8 xl:gap-x-12 lg:items-center">
                  <div className="lg:col-span-6">
                    <h1 className="block text-3xl font-bold text-gray-800 sm:text-4xl md:text-5xl lg:text-6xl ">
                      Cebika Congo
                    </h1>
                    <p className="mt-3 text-lg text-gray-800 text-justify">
                      As Cebika expands its footprint in Congo, it steers
                      ongoing and forthcoming initiatives towards the
                      establishment of poultry and beef farming, a
                      state-of-the-art processed meat factory, pioneering
                      veterinary medicines project, and the development of poly
                      clinics. With these endeavors in progress and on the
                      horizon, Cebika is poised to redefine agricultural
                      practices, elevate veterinary care standards, and enhance
                      healthcare accessibility throughout Congo.
                    </p>{" "}
                    <p className="mt-3 text-lg text-gray-800 text-justify">
                      These strategic projects underline Cebika's commitment to
                      not only meet immediate market needs but also to pioneer
                      sustainable agricultural practices, revolutionize
                      veterinary care, and bolster healthcare infrastructure in
                      the region. By driving innovation across multiple sectors,
                      Cebika aims to create a lasting impact, empowering local
                      communities and fostering economic growth in Congo.
                    </p>
                  </div>
                </div>
              </div>
            ) : null}

            {selectedCountry === "mauritania" ? (
              <div className="max-w-[85rem] mx-auto px-4 mt-6 sm:px-6 lg:px-8">
                <div className="grid lg:grid-cols-9 lg:gap-x-8 xl:gap-x-12 lg:items-center">
                  <div className="lg:col-span-6">
                    <h1 className="block text-3xl font-bold text-gray-800 sm:text-4xl md:text-5xl lg:text-6xl ">
                      Cebika Mauritania
                    </h1>
                    <p className="mt-3 text-lg text-gray-800 text-justify">
                      As Cebika extends its presence in Mauritania, it focuses
                      on exporting fish to global markets, and locally
                      assembling fresh© electric products and home appliances.
                      Leveraging the abundant marine resources, Cebika leads in
                      trading fish, catering to exporting to global markets,
                      contributing to the economic landscape of Mauritania.
                    </p>
                    <p className="mt-3 text-lg text-gray-800 text-justify">
                      Simultaneously, Cebika is at the forefront of assembling
                      fresh company electric products within Mauritania. This
                      local assembly empowers the community, fostering economic
                      growth and technological advancement within the region.
                      Through these initiatives, Cebika is not only expanding
                      its market reach but also contributing to local employment
                      and skill development, positioning Mauritania as a hub for
                      both the fishing industry and local technological
                      innovation
                    </p>
                  </div>
                </div>

                <div className="mt-2 flex gap-8">
                  <div class="p-4 md:p-7 flex justify-center items-center bg-gray-100 rounded-lg ">
                    <img
                      className="w-[300px] rounded-xxl shadow-sm"
                      src={fresh}
                      alt="company logo"
                    />
                  </div>
                </div>
              </div>
            ) : null}

            {selectedCountry === "mozambique" ? (
              <div className="max-w-[85rem] mx-auto px-4 mt-6 sm:px-6 lg:px-8">
                <div className="grid lg:grid-cols-9 lg:gap-x-8 xl:gap-x-12 lg:items-center">
                  <div className="lg:col-span-6">
                    <h1 className="block text-3xl font-bold text-gray-800 sm:text-4xl md:text-5xl lg:text-6xl ">
                      Cebika Mozambique
                    </h1>
                    <p className="mt-3 text-lg text-gray-800 text-justify">
                      As Cebika advances its operations in Mozambique, it
                      focuses on pivotal ventures, including the establishment
                      of a pioneering poultry farm and entering the tech scene
                      through ride-hailing app startup named Viva Taxi. With a
                      commitment to agricultural advancement, Cebika spearheads
                      the development of a state-of-the-art poultry farm in
                      Mozambique, contributing to local food production and
                      economic growth.
                    </p>
                    <p className="mt-3 text-lg text-gray-800 text-justify">
                      Simultaneously, Cebika's Viva Taxi, a nascent ride-hailing
                      app startup, marks a stride in technological innovation
                      within the region. This aligns with Cebika's commitment to
                      fostering entrepreneurship and digital advancements,
                      contributing to job creation and the evolution of
                      Mozambique's technological landscape.
                    </p>
                    <p className="mt-3 text-lg text-gray-800 text-justify">
                      Through these strategic endeavors, Cebika is not only
                      fostering agricultural development but also supporting
                      technological innovation, aiming to drive economic
                      progress and create opportunities for the local community
                      in Mozambique.
                    </p>
                  </div>
                </div>

                <div className="mt-2 flex gap-8">
                  <div class="p-4 md:p-7 flex justify-center items-center bg-gray-100 rounded-lg ">
                    <img
                      className="w-[200px] rounded-xxl shadow-sm"
                      src={viva}
                      alt="company logo"
                    />
                  </div>
                </div>
              </div>
            ) : null}

            {selectedCountry === "tanzania" ? (
              <div className="max-w-[85rem] mx-auto px-4 mt-6 sm:px-6 lg:px-8">
                <div className="grid lg:grid-cols-9 lg:gap-x-8 xl:gap-x-12 lg:items-center">
                  <div className="lg:col-span-6">
                    <h1 className="block text-3xl font-bold text-gray-800 sm:text-4xl md:text-5xl lg:text-6xl ">
                      Techno Vet Center
                    </h1>
                    <p className="mt-3 text-lg text-gray-800 text-justify">
                      At the core of our operations in Tanzania and Mozambique,
                      Techno Vet Center takes the lead in distributing top-tier
                      veterinary products across Dar es Salaam, Tanzania, and
                      operates commercial layer farms in both Dar es Salaam and
                      Maputo, Mozambique. Navigating complex regulatory
                      environments, Techno Vet Center ensures the seamless
                      availability of high-quality veterinary solutions. Our
                      strategic partnerships with veterinary players and our
                      dedicated presence in the commercial layer farming
                      industry fortify our commitment to providing essential
                      veterinary products and fostering agricultural development
                      across Tanzania and Mozambique.
                    </p>
                  </div>
                </div>

                <div className="mt-2 flex gap-8">
                  <div class="p-4 md:p-7 flex justify-center items-center bg-gray-100 rounded-lg ">
                    <img
                      className="w-[300px] rounded-xxl"
                      src={soocom}
                      alt="company logo"
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Markets;
