import React, { useEffect } from "react";
import { Divider } from "@mantine/core";

function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="pt-[7rem] flex flex-col justify-center items-center text-center h-full">
      <div className="max-w-[65%] mt-5">
        <h1 className="text-4xl font-bold">Contact Us</h1>
        <div className="grid grid-cols-3 mt-10 h-full justify-center">
          <div className="col-span-3 md:col-span-1">
            <div className="flex flex-col justify-center items-center">
              <div className="card flex py-3">
                <div className="flex flex-col text-center justify-center items-center">
                  <h3 className="font-semibold text-gray-800 ">
                    🇦🇪 United Arab Emirates
                  </h3>
                  <h4 className="mt-2">Cebika Incorporation</h4>
                  <p className="mt-1 text-sm text-gray-500">
                    Office 10 - J-0303 Seeh Shoaib 2
                    <br /> Dubai Industrial City, Bur Dubai <br /> Dubai, United
                    Arab Emirates
                  </p>
                  <small className="mt-1 text-lg font-medium text-gray-500">
                    +971-50-8906398
                  </small>
                  <span className="mt-1   gap-x-2 text-lg font-medium text-gray-600 hover:text-gray-800">
                    info@cebika.ae
                  </span>
                </div>
              </div>
              <Divider className="my-5" />
              <div className="card flex py-3">
                <div className="flex flex-col text-center justify-center items-center">
                  <h3 className="font-semibold text-gray-800 ">🇨🇩 Congo DRC</h3>
                  <p className="mt-1 text-sm text-gray-500">Kinshasa, Congo</p>
                  <span className="mt-1   gap-x-2 text-lg font-medium text-gray-600 hover:text-gray-800">
                    info@cebika.ae
                  </span>
                </div>
              </div>
              <Divider className="my-5" />
              <div className="card flex py-3">
                <div className="flex flex-col text-center justify-center items-center">
                  <h3 className="font-semibold text-gray-800 ">
                    🇲🇿 Mozambique
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Maputo, Mozambique
                  </p>
                  <span className="mt-1   gap-x-2 text-lg font-medium text-gray-600 hover:text-gray-800">
                    info@cebika.ae
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-3 md:col-span-1">
            <div className="flex flex-col justify-center items-center">
              <div className="card flex py-3">
                <div className="flex flex-col text-center justify-center items-center">
                  <h3 className="font-semibold text-gray-800 ">
                    🇸🇦 Saudi Arabia
                  </h3>
                  <h4 className="mt-2">Life Gate KSA</h4>
                  <p className="mt-1 text-sm text-gray-500">
                    Olaya Towers, Level 29, Olaya Main Street, Riyadh
                    <br /> P.O. Box 50182 Riyadh 11523,
                    <br /> Kingdom of Saudi Arabia
                  </p>
                  <small className="mt-1 text-lg font-medium text-gray-500">
                    +966-11-2978027
                  </small>
                  <span className="mt-1   gap-x-2 text-lg font-medium text-gray-600 hover:text-gray-800">
                    info@lifegate.us
                  </span>
                </div>
              </div>
              <Divider className="my-5" />
              <div className="card flex py-3">
                <div className="flex flex-col text-center justify-center items-center">
                  <h3 className="font-semibold text-gray-800 ">
                    🇲🇷 Mauritania
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Nouakchott, Mauritania
                  </p>
                  <p className="mt-1 text-sm text-gray-500">
                    Nouadhibou, Mauritania
                  </p>
                  <span className="mt-1   gap-x-2 text-lg font-medium text-gray-600 hover:text-gray-800">
                    info@cebika.ae
                  </span>
                </div>
              </div>
              <Divider className="my-5" />
              <div className="card flex py-3">
                <div className="flex flex-col text-center justify-center items-center">
                  <h3 className="font-semibold text-gray-800 ">🇹🇿 Tanzania</h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Dar es Salaam, Tanzania
                  </p>
                  <span className="mt-1   gap-x-2 text-lg font-medium text-gray-600 hover:text-gray-800">
                    info@cebika.ae
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-3 md:col-span-1">
            <div className="flex flex-col justify-center items-center">
              <div className="card flex  py-3">
                <div className="flex flex-col text-center justify-center items-center">
                  <h3 className="font-semibold text-gray-800 ">🇪🇬 Egypt</h3>
                  <h4 className="mt-2">Life Gate Egypt</h4>
                  <p className="mt-1 text-sm text-gray-500">
                    41B Heliopolis Gardens Buildings Complex, Autostrad Road,{" "}
                    <br />
                    Sheraton Heliopolis, Cairo, Egypt
                  </p>
                  <small className="mt-1 text-lg font-medium text-gray-500">
                    +20-226174354 <br /> +20-226174465
                  </small>
                  <span className="mt-1   gap-x-2 text-lg font-medium text-gray-600 hover:text-gray-800">
                    info@lifegate.us
                  </span>
                  <div className="border-t-4 border-slate-900 mt-2" />
                  <h4 className="mt-2">
                    ARAB DEVELOPMENT & REAL ESTATE INVESTMENT S.A.E
                  </h4>
                  <p className="mt-1 text-sm text-gray-500">
                    Benha, Qalyubia Governorate, Egypt
                  </p>
                  <small className="mt-1 text-lg font-medium text-gray-500">
                    +2-010-27600-929
                  </small>
                  <span className="mt-1   gap-x-2 text-lg font-medium text-gray-600 hover:text-gray-800">
                    info@arab-egypt.com{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="my-40">
          <div class="mapouter">
            <div class="gmap_canvas">
              <iframe
                style={{
                  width: "100%",
                }}
                className="rounded-md"
                title="map"
                width="600"
                height="500"
                id="gmap_canvas"
                src="https://maps.google.com/maps?q=Dubai,%20United%20Arab%20Emirates&t=&z=13&ie=UTF8&iwloc=&output=embed"
                frameborder="0"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
