import React, { useEffect } from "react";
import { PiHeartBold, PiPawPrint } from "react-icons/pi";
import { AiOutlineWechat } from "react-icons/ai";
import { useInView } from "react-intersection-observer";
import { useAnimation, motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import featureOne from "../assets/images/feature1.webp";
import featureTwo from "../assets/images/feature2.webp";
import featureThree from "../assets/images/feature3.webp";
import featureFour from "../assets/images/feature4.webp";

function Services() {
  const controls1 = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();
  const controls4 = useAnimation();

  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: false,
  });

  const [ref1, inView1] = useInView({
    threshold: 0.2,
    triggerOnce: false,
  });

  const [ref2, inView2] = useInView({
    threshold: 0.2,
    triggerOnce: false,
  });

  const [ref3, inView3] = useInView({
    threshold: 0.2,
    triggerOnce: false,
  });

  const [ref4, inView4] = useInView({
    threshold: 0.2,
    triggerOnce: false,
  });

  useEffect(() => {
    if (inView) {
      const html = document.documentElement;
      const body = document.body;
      html.style.transition = "all 0.5s ease-in-out";
      html.style.backgroundColor = "#252525";
      body.style.backgroundColor = "#252525";
      body.style.color = "white";
    } else {
      const html = document.documentElement;
      const body = document.body;
      html.style.backgroundColor = "white";
      body.style.backgroundColor = "white";
      body.style.color = "black";
    }

    return () => {
      const html = document.documentElement;
      const body = document.body;
      html.style.backgroundColor = "white";
      body.style.backgroundColor = "white";
      body.style.color = "black";
    };
  }, [inView]);

  useEffect(() => {
    if (inView1) {
      controls1.start("visible");
    } else {
      controls1.start("hidden");
    }
  }, [inView1, controls1]);

  useEffect(() => {
    if (inView2) {
      controls2.start("visible");
    } else {
      controls2.start("hidden");
    }
  }, [inView2, controls2]);

  useEffect(() => {
    if (inView3) {
      controls3.start("visible");
    } else {
      controls3.start("hidden");
    }
  }, [inView3, controls3]);

  useEffect(() => {
    if (inView4) {
      controls4.start("visible");
    } else {
      controls4.start("hidden");
    }
  }, [inView4, controls4]);

  const paragraphVariant = {
    hidden: {
      opacity: 0,
      y: 100,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeInOut",
      },
    },
  };

  const imageVariant = {
    hidden: {
      opacity: 0,
      y: 100,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeInOut",
      },
    },
  };

  return (
    <div
      ref={ref}
      className="flex flex-col gap-[50px] items-center justify-center max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8"
    >
      <div className="flex w-full justify-center gap-5 items-center container mt-10">
        <div className="flex w-full flex-col ">
          <h1 className="md:text-6xl text-4xl font-bold">Our Services</h1>
          <p className="md:text-2xl sm:text-lg mt-2">
            We provide a wide range of services to help you achieve your goals.
          </p>
        </div>
      </div>

      <div
        ref={ref1}
        className="feature mt-10 flex flex-col md:flex-row justify-center w-[100%]  items-center  gap-20 container"
      >
        <div className="feature__text flex flex-col justify-center items-start max-w-lg">
          <motion.h1
            animate={controls1}
            initial="hidden"
            variants={paragraphVariant}
            className="text-4xl font-bold my-5"
          >
            <HiOutlineClipboardDocumentList
              color="#00a9ff"
              style={{
                filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5))",
              }}
            />
          </motion.h1>
          <motion.h1
            animate={controls1}
            initial="hidden"
            variants={paragraphVariant}
            className="text-4xl font-bold"
          >
            Enabling Access, Ensuring Compliance
          </motion.h1>
          <motion.p
            animate={controls1}
            initial="hidden"
            variants={{ ...paragraphVariant, delay: 0.5 }}
            className="text-xl mt-3 text-justify"
          >
            Cebika focuses on seamless registration and distribution of
            essential resources and products, emphasizing regulatory compliance
            and strategic collaborations. Our efforts ensure these vital
            resources reach those in utmost need.
          </motion.p>
          <NavLink to="services/registering-distribution">
            <div className="mt-5 px-5 py-2 hover:bg-primary rounded transition-all duration-300 ease-in-out">
              > Learn More
            </div>
          </NavLink>
        </div>
        <motion.div
          animate={controls1}
          initial="hidden"
          variants={{ ...imageVariant, delay: 0.5 }}
          className="feature-1__image rounded flex justify-center w-[100%]"
        >
          <img
            className="rounded-md shadow-lg"
            src={featureOne}
            alt="feature-1"
          />
        </motion.div>
      </div>

      <div
        ref={ref2}
        className="feature mt-10 flex flex-col-reverse md:flex-row gap-10 items-center justify-center container "
      >
        <motion.div
          animate={controls2}
          initial="hidden"
          variants={imageVariant}
          className="feature-1__image md:max-w-[60%] rounded"
        >
          <img
            className="rounded-md shadow-lg"
            src={featureTwo}
            alt="feature-2"
          />
        </motion.div>
        <div className="feature__text flex flex-col justify-center items-start max-w-md">
          <motion.h1
            animate={controls2}
            initial="hidden"
            variants={paragraphVariant}
            className="text-4xl font-bold my-5"
          >
            <PiHeartBold
              color="#00a9ff"
              style={{
                filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2))",
              }}
            />
          </motion.h1>
          <motion.h1
            animate={controls2}
            initial="hidden"
            variants={paragraphVariant}
            className="text-4xl font-bold"
          >
            Facilitating Accessible Healthcare
          </motion.h1>
          <motion.p
            animate={controls2}
            initial="hidden"
            variants={{ ...paragraphVariant, delay: 0.5 }}
            className="text-xl mt-3 text-justify"
          >
            We specialize in licensing and bringing cutting-edge medical devices
            and equipment into local markets. Our commitment lies in ensuring
            access to innovative healthcare solutions for better patient care.
          </motion.p>
          <NavLink to="services/registering-distribution">
            <div className="mt-5 px-5 py-2 hover:bg-primary rounded transition-all duration-300 ease-in-out">
              > Learn More
            </div>
          </NavLink>
        </div>
      </div>

      <div
        ref={ref3}
        className="feature mt-10 flex flex-col md:flex-row gap-10 items-center justify-center container "
      >
        <motion.div
          animate={controls3}
          initial="hidden"
          variants={paragraphVariant}
          className="feature__text flex flex-col justify-center items-start max-w-md"
        >
          <h1 className="text-4xl font-bold my-5">
            <PiPawPrint
              color="#00a9ff"
              style={{
                filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2))",
              }}
            />
          </h1>
          <h1 className="text-4xl font-bold">Animal Welfare Advocacy</h1>
          <p className="text-xl mt-3 text-justify">
            Witness our commitment to animal welfare building sustainable farms,
            providing vital vaccines, and championing healthier livestock
            practices.
          </p>
          <NavLink to="services/poultry">
            <div className="mt-5 px-5 py-2 hover:bg-primary rounded transition-all duration-300 ease-in-out">
              > Learn More
            </div>
          </NavLink>
        </motion.div>
        <motion.div
          animate={controls3}
          initial="hidden"
          variants={imageVariant}
          className="feature-1__image md:max-w-[60%] rounded"
        >
          <img className="rounded-md" src={featureThree} alt="feature-3" />
        </motion.div>
      </div>

      <div
        ref={ref4}
        className="feature mt-10 flex flex-col-reverse md:flex-row gap-10 items-center justify-center container "
      >
        <motion.div
          animate={controls4}
          initial="hidden"
          variants={imageVariant}
          className="feature-1__imag md:max-w-[60%] rounded"
        >
          <img className="rounded-md" src={featureFour} alt="feature-4" />
        </motion.div>
        <motion.div
          animate={controls4}
          initial="hidden"
          variants={paragraphVariant}
          className="feature__text flex flex-col justify-center items-start max-w-md"
        >
          <h1 className="text-4xl font-bold my-5">
            <AiOutlineWechat
              color="#00a9ff"
              style={{
                filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1))",
              }}
            />
          </h1>
          <h1 className="text-4xl font-bold">Advisory Services</h1>
          <p className="text-xl mt-3 text-justify">
            Providing strategic management consultancy services to corporate
            executives and leadership groups, our advisory team operates as a
            trusted partner, prioritizing client-centric approaches.
          </p>
          <NavLink to="services/consultation">
            <div className="mt-5 px-5 py-2 hover:bg-primary rounded transition-all duration-300 ease-in-out">
              > Learn More
            </div>
          </NavLink>
        </motion.div>
      </div>
    </div>
  );
}

export default Services;
